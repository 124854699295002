import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { ToggleButton } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const ToggleExample = () => {
  const [checked1, setChecked1] = React.useState(true);
  return <ToggleButton id="toggle-button" label="Label" checked={checked1} onChange={() => setChecked1(!checked1)} mdxType="ToggleButton" />;
};
export const DefaultToggleExample = () => {
  const [checked2, setChecked2] = React.useState(true);
  return <ToggleButton id="toggle-button-default" label="Label" checked={checked2} onChange={() => setChecked2(!checked2)} mdxType="ToggleButton" />;
};
export const HorizontalToggleExample = () => {
  const [checked2, setChecked2] = React.useState(true);
  return <div style={{
    maxWidth: '160px'
  }}>
      <ToggleButton id="toggle-button-horizontal" label="Label" variant="inline" checked={checked2} onChange={() => setChecked2(!checked2)} mdxType="ToggleButton" />
    </div>;
};
export const _frontmatter = {
  "slug": "/components/toggle-button",
  "title": "ToggleButton",
  "navTitle": "ToggleButton"
};
const layoutProps = {
  ToggleExample,
  DefaultToggleExample,
  HorizontalToggleExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <ToggleExample mdxType="ToggleExample" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`A label should always be provided with a toggle button.`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Make sure that the label is clear and concise. A good toggle button label describes the effect that will happen when the toggle button is set to "On" position.`}</li>
          <li parentName="ul">{`For example, use the label "`}<em parentName="li">{`Allow notifications`}</em>{`" instead of "`}<em parentName="li">{`Do you want to receive notifications?`}</em>{`".`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The toggle button should have an immediate effect when activated.`}
        <ul parentName="li">
          <li parentName="ul">{`Toggle buttons have been inspired by real-world physical switches (e.g. a light switch). Therefore users expect something to happen immediately when the toggle button is activated.`}
            <ul parentName="li">
              <li parentName="ul">{`Also, avoid using toggle buttons if the effect requires an asynchronous call.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Toggle buttons must not be used in forms that require pressing a submit button in order to the selection come into effect - unless they have an immediate effect inside the form.`}</li>
          <li parentName="ul">{`If your toggle button does not have an immediate effect, use a single `}<InternalLink href="/components/checkbox" mdxType="InternalLink">{`Checkbox component`}</InternalLink>{` instead. Also see `}<InternalLink href="/foundation/guidelines/checkbox-radiobutton-toggle" mdxType="InternalLink">{`guidelines for choosing between radiobuttons, checkboxes and toggles.`}</InternalLink></li>
        </ul>
      </li>
      <li parentName="ul">{`Toggle button will always have a default option. Toggle buttons that do not have a state at all (neither "On" or "Off") must not be used.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The default HDS toggle button variant includes a label above the toggle button familiar from other HDS form components.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <DefaultToggleExample mdxType="DefaultToggleExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-a-horizontal-label",
      "style": {
        "position": "relative"
      }
    }}>{`With a horizontal label`}<a parentName="h4" {...{
        "href": "#with-a-horizontal-label",
        "aria-label": "with a horizontal label permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The toggle button label can also be positioned horizontally. This alignment is often used on smaller screens (e.g. mobile phones) and tighter user interface spaces.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <HorizontalToggleExample mdxType="HorizontalToggleExample" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      